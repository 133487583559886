import { useTranslation } from 'react-i18next';

import { useLazyGetUserBonusEventsQuery } from 'store/user/userApi';
import Button from 'components/shared/buttons/Button/Button';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';

import cashbackSportPreview from './assets/cashback-sport-preview.jpg';
import cashbackSportBanner from './assets/cashback-sport-banner.jpg';
import cashbackCasinoPreview from './assets/cashback-casino-preview.jpg';
import cashbackCasinoBanner from './assets/cashback-casino-banner.jpg';
import freebetBanner from './assets/banner-freebet.jpg'
import bonusBanner from './assets/banner-bonus.jpg'
import cashbackBanner from './assets/banner-cashback-en.jpg'

export type BonusListType = {
  title: string;
  subTitle: string;
  preview: string;
  banner: string;
  body: JSX.Element;
  id: string;
};

type CheckBonusEventsProps = {
  eventName: string;
}

const CheckBonusEvents = ({ eventName }: CheckBonusEventsProps) => {
  const { t } = useTranslation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const [check, { data: bonusEvents = [], isSuccess }] = useLazyGetUserBonusEventsQuery();


  const currentEvetn = bonusEvents.find((item) => item.eventName === eventName);
  const isEmpty = !currentEvetn && isSuccess;

  const handleCheckClick = async () => {
    if (isAuth) {
      await check();
    }
  };

  if (!isAuth) {
    return null;
  }

  return (
    <div className="">
      <div>
        {currentEvetn && (
          <div>
            <p><b>{t('bonus-events.negative-message')}</b></p>
            <p>{t('bonus-events.coupons-count')}: <b>{currentEvetn.couponCount}</b></p>
          </div>
        )}
        {isEmpty && <p><b>{t('bonus-events.negative-message')}...</b></p>}
      </div>
      <Button type="button" onClick={handleCheckClick}>
        {t('bonus-events.check-btn')}
      </Button>
    </div>
  );
};

export const bonusListRu: BonusListType[] = [];

export const bonusListUA: BonusListType[] = [];

export const bonusListEN: BonusListType[] = [
  {
    title: 'Free bet',
    subTitle: 'Customer gets ksh 50 free bet upon registration. (To help punter explore the platform)',
    preview: freebetBanner,
    banner: freebetBanner,
    body:
      <>
        <h3>Customer gets ksh 50 free bet upon registration. (To help punter explore the platform)</h3>
        <p>
          How it works:<br />
          Punter must play 1 game with odd of 1
        </p>
      </>,
    id: '1'
  },
  {
    title: 'Karibu bonus',
    subTitle: 'Karibu Bonus is a promotion offered by Betkali to reward new sign-up deposits by offering them a karibu bonus equal to the amount they deposit',
    preview: bonusBanner,
    banner: bonusBanner,
    body:
      <>
        <h3>Karibu Bonus is a promotion offered by Betkali to reward new sign-up deposits by offering them a karibu bonus equal to the amount they deposit</h3>
        <p>Rules:</p>
        <ol>
          <li>A bonus of 100% of the first deposit is awarded to the customer</li>
          <li>The bonus turnover for pre-game is 3x the bonus amount. The tickets must be 3 and have a minimum of 3 games per ticket with minimum odds of 1.8 per game played.</li>
          <li>For crash games, the customer must play a minimum turnover 30x of the bonus where ASTEROID MUST take 15x while the rest of the turnover can be spent on any other crash on our platform.</li>
          <li>Live games and cashout does not qualify for bonus rules</li>
          <li>Betslips that have been cashed out will not be counted</li>
          <li>The maximum amount that the bonus applies is 20,000 KES</li>
        </ol>
        <p>Customer gets 100% karibu bonus on deposits up to 20k</p>
      </>
    ,
    id: '2'
  },
  {
    title: '100% Cash back',
    subTitle: 'Reference to “You” “Your” the “Player” or “Customer(s)” is reference to any person using Betkali’s services across all available channels, which include our website',
    preview: cashbackBanner,
    banner: cashbackBanner,
    body:
      <>
        <h3>Definitions</h3>
        <p>Reference to “You” “Your” the “Player” or “Customer(s)” is reference to any person using Betkali’s services across all available channels, which include our website.</p>
        <p>Free bet: This is a non-cash consolation prize that is granted to a customer by Betkali. The free bet shall be reflected in a customer’s free bet wallet and may be used to place bets or converted to cash under certain circumstances which are determined by Betkali from time to time.</p>

        <h3>How it Works</h3>
        <p>
          The cash back is applicable to all customers who place cash multi-bets with a minimum of eight (7) selections in each bet slip on sportsbook events (pre-match and/or live).
        </p>
        <p>Only cash bets placed on online channels are eligible for the cash back.</p>
        <p>The minimum stake is Ksh. 100 </p>
        <p>
          If your bet slip has 7-19 games and only one selection loses, you will get 100% of your net stake back as a cash back if each of the remaining winning matches have minimum odds of 1.60.
        </p>
        <p>
          If your bet slip has more than 19 games and only one selection loses, you will get back 500% of your net stake back as a cash back if each of the remaining winning matches has minimum odds of 1.60.
        </p>
        <p>
          In case your bet slip has more than 15 matches, and two selections lose you will get back 50% of your net stake back as a free bet if each of the remaining winning matches has minimum odds of 1.60.
        </p>
        <p>The maximum free bet amount that a customer can be rewarded with per bet is Ksh. 10,000</p>
        <p>There will be no minimum odds required to place your bet with the cash back bonus.
        </p>
        <p>Cash back are non-withdrawable and cash back stakes are not included in any returns.</p>
        <p>The cash back should be inform of bonus not cash. </p>
        <p>To redeem the bonus the player must play with odd of 9.99</p>
        <p>In case a bet placed with cash back wins, the stake will be retained. An example is shown below:</p>

        <table>
          <tr>
            <th>Odds</th>
            <th>Status</th>
            <th>Bet Status</th>
          </tr>
          <tr>
            <td>Game 1</td>
            <td>1.6</td>
            <td>Won</td>
          </tr>
          <tr>
            <td>Game 2</td>
            <td>2</td>
            <td>Won</td>
          </tr>
          <tr>
            <td>Game 3</td>
            <td>3.3</td>
            <td>Won</td>
          </tr>
          <tr>
            <td>Game 4</td>
            <td>1.79</td>
            <td>Lost</td>
          </tr>
          <tr>
            <td>Game 5</td>
            <td>1.84</td>
            <td>Won</td>
          </tr>
          <tr>
            <td>Game 6</td>
            <td>1.66</td>
            <td>Won</td>
          </tr>
          <tr>
            <td>Game 7</td>
            <td>1.89</td>
            <td>Won</td>
          </tr>
          <tr>
            <td>Game 8</td>
            <td>1.94</td>
            <td>Won</td>
          </tr>
        </table>

        <table>
          <tr>
            <th>Stake Ksh</th>
            <th>Excise tax (7.5%)</th>
            <th>Net stake</th>
            <th>Gross Possible</th>
            <th>Total Odds</th>
          </tr>
          <tr>
            <td>100</td>
            <td>6.98</td>
            <td>93.02</td>
            <td>211.69</td>
            <td>19,691.68</td>
          </tr>
        </table>

        <p>Cash back refund</p>
        <table>
          <tr>
            <th>Net Stake (Ksh)</th>
            <th>Net Stake (Ksh)</th>
          </tr>
          <tr>
            <td>93.02</td>
            <td>93.02</td>
          </tr>
        </table>

        <p>Cash back value: Ksh. 93.02</p>
        <p>Odds: 5.0</p>
        <p>Win: 93.02 x 5.0= 465.1</p>
        <p>Less cash back stake: 465.1 - 93.02= 372.08</p>
        <p>Less withholding tax: 74.42</p>
        <p>Final payout: Ksh. 297.66</p>

        <h3>Terms and Conditions</h3>
        <p>
          Customers are not limited to the number of bets they may place in order to be eligible for the 500% cash back bonus.
        </p>
        <p>
          Casino, Virtuals, Comet Crash, jackpot, voided, canceled, bonus, cash back and cashed-out bets will not be eligible for the 500% cash back.
        </p>
        <p>
          All winnings derived from bets placed using the cashback shall be added to the customer’s cash balance in their Betkali wallet.
        </p>
        <p>The awarded cashback will expire after 24 hours from the date of award</p>
        <p>Bets placed with cashback must have a minimum odds of 4.99</p>
        <p>
          Betkali does not guarantee the availability of the 500% cashback on all devices and channels or its availability all the time.
        </p>
        <p>The 500% cashback may be amended from time to time by Betkali at its sole discretion.</p>
        <p>
          This product is provided “as is”, without warranties of any kind, either express or implied, including without limitation, warranties of title, non-infringement, non-interference, accuracy of data, accuracy of translation, availability, timing, merchantability, fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction, or modification under the laws applicable to Betkali’s General Terms and Conditions and Privacy Policy.
        </p>

        <h3>Jackpot:</h3>
        <p>- Bahatika daily jackpot – 100K</p>
        <p>- 10 games 2 way</p>
      </>
    ,
    id: '3'
  }
];

export const bonusListKZ: BonusListType[] = [];

export const bonusListAZ: BonusListType[] = [];

export const bonusListUZ: BonusListType[] = [];
