import { api } from 'store/api';
import { APIRoute } from 'utils/routes';
import { GameCardItemType } from 'components/shared/GamesCardsWidget/GamesCard/GamesCard';

import { AdapterSlots, transformGamesList } from './converters';

import type {
  GamesList,
  SlotLink,
  GetLinkParams,
  GamesListResponse,
  InbetGamesResponse,
  InbetGame,
  InbetSession,
  InbetSessionResponse,
  RplGame,
  RplSession,
  RplGamesResponse,
  RplSessionResponse,
  StartVegasSession,
  VegasGames,
  MascotGames,
  StartMascotSession,
  AgtGames,
  StartAgtSession,
  AgtStartSessionResponse,
  TVBetSessionServer,
  TVBetSession,
  SmartsoftGame,
  CreateSmartsoftSession,
  SmartsoftSessionResponse,
  StartAviatrixSession,
} from 'types/slots-data';


const getLinkParams: GetLinkParams = {
  'platform': 'mobile',
  'exit_url': 'http://54.164.253.99:3000/slots',
  'cash_url': 'http://54.164.253.99:3000/slots',
  'language': 'en',
};

interface BetGamesTokenRes {
  'id': number;
  'token': string;
  'account_id': number;
  'updated': string;
  'currency': string;
  'partner_code_betgames'?: string;
  'partner_code_twain'?: string;
}

interface BetGamesToken {
  'id': number;
  'token': string;
  'accountId': number;
  'updated': string;
  'currency': string;
  'partnerCodeBetgames'?: string;
  'partnerCodeTwain'?: string;
}

interface PragmaticGameServer {
  'game_id': string;
  'game_name': string;
  'game_type_id': string;
  'data_type'?: string | null;
}

interface PragmaticGame {
  'gameId': string;
  'gameName': string;
  'gameTypeId': string;
  'dataType'?: string | null;
}

interface GetPragmaticLobbyGames {
  category: 'all' | 'new' | 'hot';
  gameId?: string | number;
}

interface CreatePragmaticSession {
  gameId: string;
  lang?: string;
  isDemo?: boolean;
  balanceType?: 'real' | 'bonus';
  lobbyUrl: string;
}

interface PragmaticSessionResponse {
  success: boolean;
  data: {
    url: string;
  };
}

interface PragmaticJackpotTierServer {
  'jackpot_tier_id'?: number | null;
  'amount': number;
  'tier': number;
}

interface PragmaticJackpotServer {
  'jackpot_id'?: string | null;
  'name': string;
  'level': string;
  'games': string;
  'status': string;
  'tiers_number'?: number | null;
  'tiers': PragmaticJackpotTierServer[];
}

interface PragmaticJackpotTier {
  'jackpotTierId'?: number | null;
  'amount': number;
  'tier': number;
}

interface PragmaticJackpot {
  'jackpotId'?: string | null;
  'name': string;
  'level': string;
  'games': string;
  'status': string;
  'tiersNumber'?: number | null;
  'tiers': PragmaticJackpotTier[];
}


export const slotsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGamesList: builder.query<GamesList, void>({
      query: () => APIRoute.SlotsGames,
      providesTags: ['Slots'],
      transformResponse: (response: GamesListResponse): GamesList =>
        transformGamesList(response),
    }),

    getLink: builder.query<string, { id: string }>({
      query: ({ id }) => ({
        url: APIRoute.SlotsCreateSession,
        method: 'POST',
        body: {
          ...getLinkParams,
          'game_id': parseInt(id, 10),
        },
      }),
      providesTags: ['Auth'],
      transformResponse: (response: SlotLink): string => response.data.link,
    }),

    getInbetGames: builder.query<InbetGame[], { gameType?: string }>({
      query: (params) => ({
        url: '/v1/inbet/games/list',
        params: {
          'game_type': params.gameType ?? 'slots'
        }
      }),
      transformResponse: (response: InbetGamesResponse): InbetGame[] => {
        const result: InbetGame[] = Object.entries(response).map(([gameId, game]) => ({
          id: gameId,
          name: game.name,
          preview: `https://flashslots.s3.amazonaws.com/thumb/300x188/${gameId}.jpeg`,
          link: `/games/inbetgames/${gameId}`
        }));

        return result;
      },
      keepUnusedDataFor: 600,
    }),

    createInbetSession: builder.mutation<InbetSession, void>({
      query: () => ({
        url: '/v1/inbet/session',
        method: 'POST',
      }),
      transformResponse: (res: InbetSessionResponse) => ({
        session: res.session,
        currency: res.currency,
        customerId: res.customer_id,
      }),
      invalidatesTags: ['Auth'],
    }),

    getRplGames: builder.query<RplGame[], { gameType?: string }>({
      query: (params) => ({
        url: '/v1/rpl/games/list',
        params: {
          'game_type': params.gameType ?? 'slots'
        }
      }),
      transformResponse: (response: RplGamesResponse) => {
        const result = Object.entries(response).map(([gameId, game]) => ({
          id: gameId,
          name: game.name,
          preview: `https://flashslots.s3.amazonaws.com/thumb/300x188/${gameId}.jpeg`,
          link: `/games/rplgames/${gameId}`
        }));

        return result;
      },
      keepUnusedDataFor: 600,
    }),

    createRplSession: builder.mutation<RplSession, void>({
      query: () => ({
        url: '/v1/rpl/session',
        method: 'POST',
      }),
      transformResponse: (res: RplSessionResponse) => ({
        session: res.session,
        currency: res.currency,
        customerId: res.customer_id,
      }),
      invalidatesTags: ['Auth'],
    }),

    getBetgamesToken: builder.query<BetGamesToken, { currency: string }>({
      query: (params) => ({
        url: 'v1/betgames/token',
        params: {
          currency: params.currency
        },
      }),
      transformResponse: (res: BetGamesTokenRes) => ({
        id: res.id,
        token: res.token,
        accountId: res.account_id,
        updated: res.updated,
        currency: res.currency,
        partnerCodeBetgames: res.partner_code_betgames,
        partnerCodeTwain: res.partner_code_twain,
      }),
      keepUnusedDataFor: 0,
    }),

    getVegasGames: builder.query<VegasGames, void>({
      query: () => 'v1/vegas/games',
      transformResponse: AdapterSlots.vegasGames,
    }),

    startVegasSession: builder.mutation<{
      status: boolean;
      data: {
        url: string;
      };
    }, StartVegasSession>({
      query: ({ gameId, lang = 'en' }) => ({
        url: 'v1/vegas/session',
        method: 'POST',
        params: {
          'game_id': gameId,
          lang,
        }
      })
    }),

    getMascotGames: builder.query<MascotGames, void>({
      query: () => 'v1/mascot/games',
      transformResponse: AdapterSlots.mascotGames,
    }),

    startMascotSession: builder.mutation<{
      status: boolean;
      data: {
        url: string;
      };
    }, StartMascotSession>({
      query: ({ gameId, lang = 'en' }) => ({
        url: 'v1/mascot/session',
        method: 'POST',
        params: {
          'game_id': gameId,
          lang,
        }
      })
    }),

    getAgtGames: builder.query<AgtGames, void>({
      query: () => 'v1/agt/games',
      transformResponse: AdapterSlots.agtGames,
    }),

    startAgtSession: builder.mutation<AgtStartSessionResponse, StartAgtSession>({
      query: ({ gameId, closeUrl }) => ({
        url: 'v1/agt/session',
        method: 'POST',
        params: {
          'game_id': gameId,
          'close_url': closeUrl,
        }
      }),
    }),

    createTVBetSession: builder.mutation<TVBetSession, void>({
      query: () => ({
        url: 'v1/tvbet/session',
        method: 'POST',
      }),
      transformResponse: (res: TVBetSessionServer) => ({
        token: res.token,
        clientId: res.client_id,
      })
    }),

    getPragmaticGames: builder.query<GameCardItemType[], void>({
      query: () => ({
        url: 'v1/pragmatic/games'
      }),
      transformResponse: (res: PragmaticGameServer[]) =>
        res.map((game) => ({
          id: game.game_id,
          name: game.game_name,
          link: `/games/pragmatic/${game.game_id}`,
          preview: `http://common-static.ppgames.net/game_pic/rec/325/${game.game_id}.png`,
          // gameTypeId: game.game_type_id,
          // dataType: game.data_type,
        }))
    }),

    getPragmaticLobbyGames: builder.query<GameCardItemType[], GetPragmaticLobbyGames>({
      query: (params) => ({
        url: 'v1/pragmatic/lobby_games',
        params,
      }),
      transformResponse: (res: PragmaticGameServer[], _, { gameId }) =>
        res.map((game) => ({
          id: game.game_id,
          name: game.game_name,
          link: `/games/pragmatic/${game.game_id}`,
          preview: `http://common-static.ppgames.net/game_pic/rec/325/${game.game_id}.png`,
          label: 'pragmatic',
          // gameTypeId: game.game_type_id,
          // dataType: game.data_type,
        })).filter((item) => gameId ? item.id === gameId : true)
    }),

    createPragmaticSession: builder.mutation<string, CreatePragmaticSession>({
      query: (params) => ({
        url: 'v1/pragmatic/session',
        method: 'POST',
        params: {
          'game_id': params.gameId,
          'lang': params.lang ?? 'en',
          'is_demo': params.isDemo,
          'balance_type': params.balanceType ?? 'real',
          'lobby_url': params.lobbyUrl,
        }
      }),
      transformResponse: (res: PragmaticSessionResponse) => res.data.url,
    }),

    getPragmaticJackpots: builder.query<PragmaticJackpot[], void>({
      query: () => 'v1/pragmatic/active_jackpots',
      transformResponse: (res: PragmaticJackpotServer[]) =>
        res.map((item) => ({
          jackpotId: item.jackpot_id,
          name: item.name,
          level: item.level,
          games: item.games,
          status: item.status,
          tiersNumber: item.tiers_number,
          tiers: item.tiers.map((tierItem) => ({
            jackpotTierId: tierItem.jackpot_tier_id,
            amount: tierItem.amount,
            tier: tierItem.tier
          }))
        }))
    }),

    getSmartsoftGames: builder.query<GameCardItemType[], void>({
      query: () => 'v1/smartsoft/games',
      transformResponse: (res: SmartsoftGame[]) => res.map((game) => ({
        id: game.game_id,
        name: game.title,
        label: 'smartsoft',
        preview: `${process.env.PUBLIC_URL}/smartsoft/${game.game_name}.jpg`,
        link: `/games/smartsoft/${game.game_name}/${game.game_category}`
      }))
    }),

    createSmartsoftSession: builder.query<string, CreateSmartsoftSession>({
      query: ({ gameCategory, gameName, returnUrl, lang }) => ({
        url: 'v1/smartsoft/session',
        method: 'POST',
        body: {
          'game_category': gameCategory,
          'game_name': gameName,
          'return_url': returnUrl,
          'lang': lang
        }
      }),
      transformResponse: (res: SmartsoftSessionResponse) => res.data.url,
    }),

    startAviatrixSession: builder.mutation<string, StartAviatrixSession>({
      query: (params) => ({
        url: 'v1/aviatrix/session',
        method: 'POST',
        body: {
          'product_id': params.productId,
          'lang': params.lang,
          'lobby_url': params.lobbyUrl,
        }
      }),
      transformResponse: (res: SmartsoftSessionResponse) => res.data.url,
    }),

  }),
});

export const {
  useGetGamesListQuery,
  useGetLinkQuery,
  useGetInbetGamesQuery,
  useCreateInbetSessionMutation,
  useCreateRplSessionMutation,
  useGetBetgamesTokenQuery,
  useGetVegasGamesQuery,
  useStartVegasSessionMutation,
  useGetMascotGamesQuery,
  useStartMascotSessionMutation,
  useGetAgtGamesQuery,
  useStartAgtSessionMutation,
  useCreateTVBetSessionMutation,
  useGetPragmaticGamesQuery,
  useGetPragmaticLobbyGamesQuery,
  useCreatePragmaticSessionMutation,
  useGetPragmaticJackpotsQuery,
  useGetSmartsoftGamesQuery,
  useCreateSmartsoftSessionQuery,
  useStartAviatrixSessionMutation,
} = slotsApi;
